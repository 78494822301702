/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import angular from 'angular';
import angularbp from 'angular-ui-bootstrap';

let cfsschools = angular.module('cfsschools',['ui.bootstrap']).controller('TypeaheadCtrl', ['$scope', '$http', function($scope, $http) {

  // var _selected;
  $scope.init = function(school){
    $scope.selectSchool(school);
  }

  $scope.selected = undefined;

  // Any function returning a promise object can be used to load values asynchronously
  $scope.getActiveSchools = function(val) {
    return $http.get('/schools/active.json', {
      params: {
        match: val,
        sensor: false
      }
    }).then(function(response){
      return response.data.map(function(item){
        return item;
      });
    });
  };

  $scope.selectSchool = function(item, model, label, event){
    $scope.school = item;
    $scope.school_id = item.id;
  };

}]);

let sfsevents = angular.module('sfsevents',['ui.bootstrap']).controller('TypeaheadCtrl', ['$scope', '$http', function($scope, $http) {

  $scope.init = function(event){
    $scope.selectEvent(event);
  }

  $scope.selected = undefined;

  $scope.getActiveEvents = function(val) {
    return $http.get('/events/active.json', {
      params: {
        match: val,
        sensor: false
      }
    }).then(function(response){
      return response.data.map(function(item){
        return item;
      });
    });
  };

  $scope.selectEvent = function(item, model, label, event){
    $scope.event = item;
    $scope.event_id = item.id;
  };

}]);

let sfsfundraisers = angular.module('sfsfundraisers',['ui.bootstrap']).controller('TypeaheadCtrl', ['$scope', '$http', function($scope, $http) {

  // var _selected;
  $scope.init = function(event_id){
    $scope.event_id = event_id;
    $scope.selectFundraiser(fundraiser);
  }

  $scope.selected = undefined;

  // Any function returning a promise object can be used to load values asynchronously
  $scope.getEventFundraisers = function(val) {
    return $http.get('/events/' + $scope.event_id + '/fundraisers/active.json', {
      params: {
        match: val,
        sensor: false
      }
    }).then(function(response){
      return response.data.map(function(item){
        return item;
      });
    });
  };

  $scope.selectFundraiser = function(item, model, label, event){
    $scope.fundraiser = item;
    $scope.fundraiser_id = item.id;
  };

}]);

let sfssponsor = angular.module('sfssponsor',['ui.bootstrap']).controller('quickamount', ['$scope', '$http', function($scope, $http) {

  // var _selected;
  $scope.init = function(amount){
    $scope.amount = amount;
  }

}]);
